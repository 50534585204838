@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body min-h-screen;
  }
}

@layer components {
  .page-container {
    max-width: 1380px;
    // @apply mx-auto;
  }

  .product-list .swiper-slide {
    width: 300px;
    height: auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    li {
      @apply hover:opacity-75 transition rounded-md font-semibold text-base border border-gray-300;
      a {
        display: block;
        text-align: center;
        @apply px-5 py-3;
      }
    }
    .selected {
      @apply bg-black text-white;
    }
  }
}

.ellipsis {
  overflow: hidden;
  height: auto;
  line-height: 25px;
  margin: 0 20px;
  &:before {
    content: "";
    float: left;
    width: 5px;
    height: 200px;
  }
  > * {
    &:first-child {
      float: right;
      width: 100%;
      margin-left: -5px;
    }
  }
  &:after {
    content: "\02026";
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    float: right;
    position: relative;
    top: -25px;
    left: 100%;
    width: 3em;
    margin-left: -3em;
    padding-right: 5px;
    text-align: right;
    background-size: 100% 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      to(white),
      color-stop(50%, white)
    );
    background: -moz-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 50%,
      white
    );
    background: -o-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 50%,
      white
    );
    background: -ms-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 50%,
      white
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 50%,
      white
    );
  }
}
